import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Ratio from 'react-bootstrap/Ratio';

import { FaRegPlayCircle } from '@react-icons/all-files/fa/FaRegPlayCircle';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';
import IconButton from '../components/IconButton/IconButton';

const IndexPage = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Layout>
      <Seo
        // TODO: Add scheme
        title="All your patient engagement in one place | Bridge"
        description="The patient engagement company for leading healthcare organizations. ONC 2015 certified patient portal, mobile app and patient engagement software vendor."
      />
      {/* Hero */}
      <Section variant="hero">
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col xxl={6} lg={6}>
              <h1>Your complete patient engagement platform</h1>
              <p className="h-font-size-lg my-4">
                <strong>Smarter technology. Happier Patients.</strong>
              </p>
              <ul className="h-clear-list mb-4">
                <li className="h-font-size-lg">
                  Unlock the{' '}
                  <Link to="/healthcare-digital-front-door/">
                    <strong>digital front door</strong>
                  </Link>
                </li>
                <li className="h-font-size-lg">
                  Increase{' '}
                  <Link to="/patient-engagement-solution/">
                    <strong>patient engagement</strong>
                  </Link>
                </li>
                <li className="h-font-size-lg">
                  Optimize{' '}
                  <Link to="/telehealth-solution/">
                    <strong>virtual care</strong>
                  </Link>
                </li>
              </ul>
              <Button
                as={Link}
                variant="primary"
                to="/view-a-demo/"
                className="mb-2 me-0 me-lg-2 mb-lg-0"
              >
                Request a demo
              </Button>
              <IconButton variant="link" onClick={handleShow}>
                watch video <FaRegPlayCircle size={20} className="mx-1" />
              </IconButton>
            </Col>
            <Col xxl={4} lg={6} className="d-none d-lg-block">
              <StaticImage
                src="../images/banner-image-bridge-min.png"
                alt="Your complete patient engagement platform"
              />
            </Col>
          </Row>
        </Container>
      </Section>
      {/* Banner */}
      <Section>
        <Container>
          <div className="c-banner__blue">
            <Row className="align-items-center">
              <Col lg={6}>
                <StaticImage
                  src="../images/problem-component-banner.jpg"
                  alt="Bridge the gap between patient expectations and provider
                operations"
                />
              </Col>
              <Col lg={6} className="p-5 p-lg-2 p-xl-0">
                <h2 className="h3 mb-2">
                  Bridge the gap between patient expectations and provider
                  operations
                </h2>
                <p className="m-0">
                  Patients want modern tools that allow for greater control over
                  their health. Bridge unifies the care journey and offers
                  patients the type of flexibility and convenience they’ve never
                  experienced in healthcare before.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </Section>
      {/* Section */}
      <Section>
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <h1 className="bicolor">
                A truly
                <br />
                patient-centered experience
              </h1>
              <p>
                Automate and orchestrate the care journey from pre to post-visit
                using one platform. Bridge allows you to create coordinated and
                seamless transitions through each stage of the patient journey -
                significantly improving the patient experience.
              </p>
            </Col>
            <Col lg={6}>
              <StaticImage
                src="../images/patient-experience-pc.jpg"
                alt="A truly patient-centered experience"
              />
            </Col>
          </Row>
          <Row className="my-2 my-sm-5">
            <Col className="text-center">
              <StaticImage
                src="../images/patient-experience-features.jpg"
                alt="A truly patient-centered experience"
              />
            </Col>
          </Row>
        </Container>
      </Section>
      {/* Section Split */}
      <Section variant="split">
        <Container fluid className="px-0">
          <Row className="justify-content-around align-items-center my-2 my-sm-5 mx-0">
            <Col lg={5} className="px-sm-5">
              <p className="h-font-size-xl text-secondary">
                <strong>Safe, secure & easy to deploy</strong>
              </p>
              <h3>Bridge is an innovator in patient engagement technology</h3>
            </Col>
            <Col lg={5} />
          </Row>
          <Row className="justify-content-around align-items-center my-5 mx-0">
            <Col lg={5} className="px-sm-5 mb-5 mb-lg-0">
              <h4>Connect multiple EHRs</h4>
              <p>
                Share with your patients the siloed EHR data they&apos;ve always
                wanted to access. Bridge&apos;s advanced interface capabilities
                opens up new opportunities for patient engagement. Seamlessly
                integrate your organization&apos;s existing source systems -
                including one or multiple EHR, PM, and RCM solutions.
              </p>
              <Button
                as={Link}
                variant="outline-secondary"
                to="/integration-services/"
              >
                Learn More
              </Button>
            </Col>
            <Col lg={5} className="text-center">
              <StaticImage
                src="../images/seamless-image.svg"
                alt="multiple ehr's graphic"
              />
            </Col>
          </Row>
          <Row className="justify-content-around align-items-center my-5 mx-0">
            <Col lg={5} className="px-sm-5 mb-5 mb-lg-0">
              <h4>Consolidate piecemealed patient engagement solutions</h4>
              <p>
                Bridge enables providers to get rid of fragmented tools, which
                are expensive to maintain, difficult to manage, and create a
                disjointed experience for patients. Engage patients across their
                entire health journey from booking an appointment to refilling
                their prescription using one platform.
              </p>
              <Button
                as={Link}
                variant="outline-secondary"
                to="/patient-engagement-solution/"
              >
                Learn More
              </Button>
            </Col>
            <Col lg={5} className="text-center">
              <StaticImage
                src="../images/piecemeal-solution.svg"
                alt="multiple ehr's graphic"
              />
            </Col>
          </Row>
          <Row className="justify-content-around align-items-center my-5 mx-0">
            <Col lg={5} className="px-sm-5 mb-5 mb-lg-0">
              <h4>Unlock the digital front door</h4>
              <p>
                With our industry-leading digital front door solution, you can
                leverage an omnichannel communications strategy that includes a
                patient portal, mobile app, telehealth, intake, and electronic
                reminders to engage patients across their care journey.
              </p>
              <Button
                as={Link}
                variant="outline-secondary"
                to="/healthcare-digital-front-door/"
              >
                Learn More
              </Button>
            </Col>
            <Col lg={5} className="text-center">
              <StaticImage
                src="../images/secure-compliant.svg"
                alt="multiple ehr's graphic"
              />
            </Col>
          </Row>
        </Container>
      </Section>
      {/* Wave */}
      <Section variant="wave">
        <Container>
          <Row className="justify-content-center mb-3 my-sm-5">
            <Col lg={8}>
              <h2 className="text-center text-white">
                Maximize your business results & reduce bottlenecks
              </h2>
            </Col>
          </Row>
          <Row className="pt-sm-5 align-items-center justify-content-center">
            <Col
              xxl={5}
              xl={5}
              className="mb-5 mb-xl-0  text-center text-sm-start"
            >
              <p className="text-white h-font-size-huge m-0">
                75%<small>+</small>
              </p>
              <p className="text-light-secondary h-font-size-xl w-sm-75">
                <strong>
                  In potential savings per month on licensing fees from
                  consolidating solutions
                </strong>
              </p>
              <Button
                as={Link}
                variant="primary"
                to="/patient-engagement-portal-app-cost-pricing/"
              >
                Review our Pricing
              </Button>
            </Col>
            <Col xxl={5} xl={7}>
              <div className="bg-white px-1 py-3 p-sm-5 rounded">
                <Row className="align-items-center pb-3 pb-sm-5">
                  <Col xs={2} className="text-center">
                    <StaticImage
                      src="../images/cross-platform-solution.svg"
                      alt="cross platform solution"
                    />
                  </Col>
                  <Col xs={9}>
                    A cross platform solution that consolidates all interactions
                    with your customers into one.
                  </Col>
                </Row>
                <Row className="align-items-center py-3 py-sm-5">
                  <Col xs={2} className="text-center">
                    <StaticImage
                      src="../images/star-Icon.svg"
                      alt="Flexible pricing"
                    />
                  </Col>
                  <Col xs={9}>
                    Flexible pricing allows our product to adapt to your
                    organization’s needs and ensures you only pay for what you
                    need.
                  </Col>
                </Row>
                <Row className="align-items-center pt-3 pt-sm-5">
                  <Col xs={2} className="text-center">
                    <StaticImage
                      src="../images/polygon-icon.svg"
                      alt="An average patient engagement increase"
                    />
                  </Col>
                  <Col xs={9}>
                    An average patient engagement increase of 40% over an
                    18-month period.
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      {/* Section Logo */}
      <Section>
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <h3 className="text-center mb-2 mb-sm-5">
                Bridge has installations across diverse healthcare systems
                nationwide.
              </h3>
            </Col>
          </Row>
          <Row className="align-items-center pt-5">
            <Col sm={6} lg={3} className="text-center">
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <StaticImage
                  src="../images/esse-health.png"
                  height={80}
                  alt=""
                />
              </div>
              <p>Large Multispecialty Practice</p>
            </Col>
            <Col sm={6} lg={3} className="text-center">
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <StaticImage
                  src="../images/well-sky-logo.svg"
                  height={80}
                  alt=""
                />
              </div>
              <p>EHR Vendor Partners</p>
            </Col>
            <Col sm={6} lg={3} className="text-center">
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <StaticImage
                  src="../images/with-health-icon.svg"
                  height={80}
                  alt=""
                />
              </div>
              <p>Telehealth-based Practice</p>
            </Col>
            <Col sm={6} lg={3} className="text-center">
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <StaticImage
                  src="../images/stlouis-hv-icon.png"
                  height={80}
                  alt=""
                />
              </div>
              <p>Specialty Practice</p>
            </Col>
          </Row>
        </Container>
      </Section>
      {/* Section CTA */}
      <Section variant="cta">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <h3 className="text-center m-0">
                Want to learn more about how Bridge can improve your business?
              </h3>
              <p className="text-center h-font-size-lg m-4 px-5">
                We&apos;re here to help you achieve all your patient engagement
                needs.
              </p>
              <p className="text-center">
                <Button as={Link} variant="primary" to="/view-a-demo/">
                  Contact Us
                </Button>
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
      {/* Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Body className="p-0">
          <Ratio aspectRatio="16x9">
            <iframe
              loading="lazy"
              src="https://www.youtube.com/embed/nbez0KGn2xo"
              title="Bridge Patient Portal"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Ratio>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default IndexPage;
